import { getParentOfType, types } from 'mobx-state-tree';

import ProductProperty from './ProductProperty';
import FullProduct from './FullProduct';
import FullProductCollectionItem from './FullProductCollectionItem';
import ProductMatrix from './ProductMatrix';

const FullProductMatrix = types.compose(
  ProductMatrix,
  types
    .model('FullProductMatrix', {
      row: ProductProperty,
      column: ProductProperty,
      items: types.array(FullProductCollectionItem),
    })
    .views((self) => {
      return {
        get product() {
          return getParentOfType(self, FullProduct);
        },
        propertyIsSelectable(property) {
          return !property.isEmpty() && property.elements.length > 1;
        },
        columnIsSelectable() {
          return self.propertyIsSelectable(self.column);
        },
        getDefaultColumnElement() {
          return !self.columnIsSelectable() ? self.column.elements[0] : null;
        },
        rowIsSelectable() {
          return self.propertyIsSelectable(self.row);
        },
        getDefaultRowElement() {
          return !self.rowIsSelectable() ? self.row.elements[0] : null;
        },
      };
    })
);

export default FullProductMatrix;
