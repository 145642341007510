import { getParentOfType, types } from 'mobx-state-tree';

import ProductCollectionProduct from '../ProductCollectionProduct';
import FullProductMatrix from './FullProductMatrix';
import ProductCollectionItem from './ProductCollectionItem';

const FullProductCollectionItem = types.compose(
  ProductCollectionItem,
  types
    .model('FullProductCollectionItem', {
      column_id: types.number,
      row_id: types.number,
      product: ProductCollectionProduct,
    })
    .views((self) => {
      return {
        getInStockQuantity() {
          return self.product.in_stock_quantity;
        },
        getIncomingQuantities() {
          return self.product.incoming_quantities;
        },
        get collection() {
          return getParentOfType(self, FullProductMatrix);
        },
        get rowName() {
          if (!self.collection) {
            return '';
          }
          const element = self.collection.row.getElementWithId(self.row_id);
          return element ? element.name : '';
        },
        get columnName() {
          if (!self.collection) {
            return '';
          }
          const element = self.collection.column.getElementWithId(
            self.column_id
          );
          return element ? element.name : '';
        },
        get name() {
          return [self.rowName, self.columnName]
            .filter((name) => !!name)
            .join(', ');
        },
      };
    })
);

export default FullProductCollectionItem;
