import { get, head, some } from 'lodash';
import {
  flow,
  getEnv,
  getRoot,
  resolveIdentifier,
  types,
} from 'mobx-state-tree';

import Paths from '../types/Paths';
import ProductAvailabilityType, {
  ProductAvailabilityTypeType,
} from '../types/ProductAvailabilityType';
import ProductClass, { ProductClassType } from '../types/ProductClass';
import ProductRecurringOrderType, {
  ProductRecurringOrderTypeType,
} from '../types/ProductRecurringOrderType';
import { ProductType } from '../types/ProductTypeClass';
import QuantityDiscountDisplayStyles from '../types/QuantityDiscountDisplayStyles';
import RequestState, { RequestStateType } from '../types/RequestState';
import SectionStatusType, {
  SectionStatusTypeType,
} from '../types/SectionStatusType';
import generatePath from '../util/generatePath';
import { roundWithPrecision } from '../util/number';
import Category from './Category';
import Manufacturer from './Manufacturer';
import Price from './Price';
import ProductImage from './ProductImage';
import ProductMatrix from './product/ProductMatrix';
import ProductMulti from './product/ProductMulti';
import ProductPriceInfo from './ProductPriceInfo';
import ProductSeason from './ProductSeason';
import PropertyImage from './PropertyImage';
import QuantityDiscounts from './QuantityDiscounts';
import Review from './Review';
import ProductMerchantInfo from './ProductMerchantInfo';

/* TODO Many props should be removed for product listing.
 *   Backend refactoring should affect this.
 * */

const Product = types
  .model('Product', {
    actual_code: types.string,
    all_category_ids: types.array(types.number),
    alsoPurchasedIds: types.array(types.string),
    alsoPurchasedState: types.optional(RequestStateType, RequestState.NONE),
    availability_html: types.maybeNull(types.string),
    availability_html_for_listing: types.maybeNull(types.string),
    availability_type: ProductAvailabilityTypeType,
    available_online: types.boolean,
    bottle_deposit: types.maybeNull(Price),
    can_be_ordered_out_of_stock: types.boolean,
    class: ProductClassType,
    collection: types.maybeNull(ProductMatrix),
    customer_group_visibilities: types.maybeNull(types.array(types.number)),
    date_added: types.string,
    description_short: types.optional(types.string, ''),
    ean: types.string,
    extra_id: types.maybeNull(types.string),
    free_quantity: types.number,
    has_required_services: types.maybeNull(types.boolean),
    id: types.identifier,
    images: types.optional(types.array(ProductImage), []),
    is_reservable_in_store: types.maybeNull(types.boolean),
    labels: types.optional(types.array(PropertyImage), []),
    main_category_id: types.maybeNull(types.number),
    main_section_id: types.maybeNull(types.number),
    manufacturer_product_id: types.maybeNull(types.string),
    merchant_info: types.maybeNull(ProductMerchantInfo),
    min_order_quantity: types.maybeNull(types.number),
    model: types.string,
    multi: types.maybeNull(ProductMulti),
    multiproduct_id: types.string,
    multiproduct_title: types.maybeNull(types.string),
    name: types.string,
    package_size: types.maybeNull(types.number),
    package_unit_name: types.maybeNull(types.string),
    price_info: types.maybeNull(ProductPriceInfo),
    product_type: ProductType,
    quantity_discounts: types.maybeNull(QuantityDiscounts),
    recurring_order_type: ProductRecurringOrderTypeType,
    reviews: types.optional(types.array(Review), []),
    reviews_average: types.number,
    reviews_count: types.number,
    reviewsState: types.optional(RequestStateType, RequestState.NONE),
    season: types.maybeNull(ProductSeason),
    section: types.maybeNull(
      types.model({
        status: SectionStatusTypeType,
        visible_to_crawlers: types.maybeNull(types.boolean),
      })
    ),
    section_ids: types.array(types.number),
    seo_description: types.maybeNull(types.string),
    singular_price_divider: types.maybeNull(types.number),
    singular_price_unit: types.maybeNull(types.string),
    slug: types.string,
    stock_unit: types.string,
    suitability_description: types.maybeNull(types.string),
    warranty: types.maybeNull(types.number),
  })
  .actions((self) => {
    const ifShoppingCenter = () =>
      getRoot(self).configStore.siteConfig.isShoppingCenter;

    return {
      getBaseApi(endpoint) {
        return ifShoppingCenter()
          ? `shopping-center/${endpoint}`
          : `${endpoint}`;
      },
      getProductsEndpoint(api, id) {
        const productId = id || self.id;
        return ifShoppingCenter()
          ? `shopping-center/products/${productId}/${api}`
          : `products/${productId}/${api}`;
      },
      loadReviews: flow(function* loadReviews() {
        if (self.reviews_count > 0) {
          self.reviewsState = RequestState.LOADING;
          const reviewsApi = self.getProductsEndpoint('reviews');
          try {
            const reviews = yield getEnv(self).apiWrapper.request(
              `${reviewsApi}`,
              {},
              { active_section: null }
            );
            self.setReviews(reviews);
            self.reviewsState = RequestState.LOADED;
          } catch (e) {
            self.reviewsState = RequestState.ERROR;
            throw e;
          }
        } else {
          // No need to load reviews, so set state to loaded.
          self.reviewsState = RequestState.LOADED;
        }
      }),
      setReviews: function setReviews(reviews) {
        self.reviews = reviews;
      },
      loadAlsoPurchased: flow(function* loadAlsoPurchased(productId) {
        self.alsoPurchasedState = RequestState.LOADING;
        const alsoPurchasedApi = productsApiEndpoint(
          'also-purchased',
          productId
        );
        try {
          const alsoPurchasedIds = yield getEnv(self).apiWrapper.request(
            `${alsoPurchasedApi}`,
            {},
            { active_section: null }
          );
          self.setAlsoPurchased(alsoPurchasedIds);
          self.alsoPurchasedState = RequestState.LOADED;
        } catch (e) {
          self.alsoPurchasedState = RequestState.ERROR;
          throw e;
        }
      }),
      setAlsoPurchased: function setAlsoPurchased(alsoPurchasedIds) {
        self.alsoPurchasedIds = alsoPurchasedIds;
      },
    };
  })
  .views((self) => {
    const getActiveCurrencyPrecision = () =>
      getRoot(self).currencyStore.activeCurrency.precision;

    // Assigning the function as a reference so we would create less new Math-functions every time it's being used.
    const roundWithPrecisionRef = roundWithPrecision;

    return {
      belongsToSomeSection: (sectionIds) => {
        return self.section_ids.some(
          (sectionId) => sectionIds.indexOf(sectionId) !== -1
        );
      },
      belongsToSection: (sectionId) => {
        return self.section_ids.indexOf(sectionId) !== -1;
      },
      belongsToCategory(categoryId) {
        if (self.allCategories) {
          return some(self.allCategories, (category) => {
            return (
              category.hierarchy.findIndex(
                (category) => category.id === categoryId
              ) !== -1
            );
          });
        }
      },
      breadcrumbsForCategory(category) {
        let breadcrumbItems = [];

        if (category) {
          breadcrumbItems = breadcrumbItems.concat(category.breadcrumbs);
        }

        breadcrumbItems.push({
          text: self.name,
          url: self.path,
        });

        return breadcrumbItems;
      },
      canBeOrdered(activeProductId) {
        const actualProduct = self.getActualProduct(activeProductId);

        if (!actualProduct) {
          return false;
        }

        if (
          actualProduct.availability_type ===
          ProductAvailabilityType.ALLOW_BACKORDER
        ) {
          return true;
        }

        if (
          actualProduct.availability_type ===
          ProductAvailabilityType.ONLY_IN_SHOP
        ) {
          return false;
        }

        if (actualProduct.season && !actualProduct.season.isActive()) {
          return false;
        }

        if (actualProduct.isTemporarilyUnavailable(activeProductId)) {
          return false;
        }

        if (
          actualProduct.availability_type ===
            ProductAvailabilityType.CLOSEOUT &&
          !actualProduct.hasEnoughStockAvailable(activeProductId)
        ) {
          return false;
        }

        return true;
      },
      getActualProduct: (activeProductId) => {
        let actualProduct = self;
        if (self.isMulti() && self.multi.childrenIncludesId(activeProductId)) {
          actualProduct = self.multi.findChild(activeProductId);
        }
        return actualProduct;
      },
      getActualProductId: ({ activeProductId = null, id = null }) => {
        if (self.isMulti() && self.multi.childrenIncludesId(activeProductId)) {
          return activeProductId;
        }
        return id;
      },
      getActualProductLoadingState: ({ id = null, state = '' }) => {
        if (self.isMulti() && self.multi.childrenIncludesId(id)) {
          return self.multi.findChild(id)[state].get(id);
        }

        return self[state].get(id);
      },
      getDiscountAmount: (quantityDiscount) => {
        let discountAmount = 0;
        if (!self.getQuantityDiscounts().hasDiscounts) {
          discountAmount = self.price_info.discount_percentage;
        }

        if (self.getQuantityDiscounts().hasDiscounts) {
          discountAmount = quantityDiscount.discount;
        }

        return discountAmount;
      },
      getDiscountInfo: (includeTax, quantityDiscountDisplayStyle) => {
        let savings = null;
        let discountAmount = null;
        let hasPriceRange =
          self.class === ProductClass.MULTI &&
          self.multi.hasPriceRange(includeTax);
        let hasDiscount = false;
        let minPrice = null;
        let maxPrice = null;

        if (!self.hasDiscount) {
          savings = 0;
          discountAmount = 0;
        }

        const baseDiscountInfo = () => {
          savings = self.price_info?.getSavings(includeTax);
          discountAmount = self.price_info?.discount_percentage;
          hasDiscount = self.price_info?.is_discount;
        };

        if (!self.getQuantityDiscounts().hasDiscounts) {
          baseDiscountInfo();
        }

        if (self.getQuantityDiscounts().hasDiscounts && !self.isMulti()) {
          const quantityDiscount = self.quantity_discounts;

          switch (quantityDiscountDisplayStyle) {
            case QuantityDiscountDisplayStyles.LOWEST:
              discountAmount = self.getDiscountAmount(quantityDiscount.lowest);
              savings = quantityDiscount.lowest.getSavings(
                self.price_info?.getNormalPrice(includeTax),
                includeTax
              );
              hasDiscount = true;
              break;
            case QuantityDiscountDisplayStyles.RANGE:
              discountAmount = self.getDiscountAmount(quantityDiscount.lowest);
              hasDiscount = true;
              hasPriceRange = true;
              minPrice = quantityDiscount.lowest;
              maxPrice = quantityDiscount.highest;
              break;
            case QuantityDiscountDisplayStyles.NORMAL_AND_LOWEST:
              hasDiscount = true;
              break;
            default:
              baseDiscountInfo();
              break;
          }
        }

        return {
          savings,
          discountAmount,
          hasDiscount,
          hasPriceRange,
          minPrice,
          maxPrice,
        };
      },
      getFreeQuantity(activeProductId) {
        const actualProduct = self.getActualProduct(activeProductId);
        if (!actualProduct) {
          return 0;
        }
        let freeQuantity = actualProduct.free_quantity;
        if (actualProduct.class === ProductClass.COLLECTION) {
          const collectionItem =
            actualProduct.collection.getItemWithProductId(activeProductId);
          freeQuantity = get(collectionItem, 'product.free_quantity', 0);
        }
        return freeQuantity;
      },
      getImage(productId) {
        return (
          self.images &&
          self.images.find((image) => image.product_id === productId)
        );
      },
      getImagesBySize: (size) => {
        return self.images && self.images.map((image) => image.sizes[size]);
      },
      getMainImage(activeProductId) {
        return (
          self.images.find((image) => image.product_id === activeProductId) ||
          head(self.images)
        );
      },
      getPackagePrice(withTax, activeProductId) {
        let product = self;
        if (activeProductId) {
          product = self.getActualProduct(activeProductId) || product;
        }

        if (!product.package_size) {
          return;
        }

        return roundWithPrecisionRef(
          product.package_size *
            roundWithPrecisionRef(
              product.getPrice(withTax, activeProductId),
              getActiveCurrencyPrecision()
            ),
          getActiveCurrencyPrecision()
        );
      },
      getPrice(withTax, activeProductId) {
        let product = self;
        if (activeProductId) {
          product = self.getActualProduct(activeProductId) || product;
        }

        return product.price_info ? product.price_info.getPrice(withTax) : 0;
      },
      getPriceWithPrecision(withTax, activeProductId) {
        let product = self;
        if (activeProductId) {
          product = self.getActualProduct(activeProductId) || product;
        }

        return product.price_info
          ? roundWithPrecisionRef(
              product.getPrice(withTax),
              getActiveCurrencyPrecision()
            )
          : 0;
      },
      getQuantityDiscounts: () => {
        return {
          hasDiscounts: !!self.quantity_discounts,
          discounts: self.quantity_discounts,
        };
      },
      getQuantityStep: (product) => {
        return product && self.sellInPackage ? self.package_size : 1;
      },
      getValidatedServices: ({ postalCode }) => {
        return self.validatedServices.get(`${self.id}--${postalCode}`);
      },
      getValidatedService: ({ postalCode, serviceId }) => {
        const validatedServices = self.getValidatedServices({
          postalCode,
        });

        return (
          self.ifValidatedServicesExists({ postalCode }) &&
          validatedServices.find((service) => service.id === serviceId)
        );
      },
      hasCustomerGroupVisibility(customerGroupId) {
        // Have to check if customer_group_visibilities doesn't exist because then backend response comes from cache and frontend cannot break.
        if (!self.customer_group_visibilities) {
          return true;
        }

        // When customer_group_visibilities is an empty list. It's hidden from everyone.
        if (
          self.customer_group_visibilities &&
          self.customer_group_visibilities.length === 0
        ) {
          return false;
        }

        return self.customer_group_visibilities.some(
          (id) => id === customerGroupId
        );
      },
      hasDeposit(activeProductId) {
        const actualProduct = self.getActualProduct(activeProductId);

        return (
          actualProduct &&
          actualProduct.bottle_deposit &&
          actualProduct.bottle_deposit.with_tax > 0
        );
      },
      hasEnoughStockAvailable(activeProductId) {
        const actualProduct = self.getActualProduct(activeProductId);

        let freeQuantity = actualProduct.getFreeQuantity(activeProductId);
        const requiredAmount = actualProduct.sellInPackage
          ? actualProduct.package_size
          : 1;

        return freeQuantity >= requiredAmount;
      },
      hasOnlyRecurringOrder() {
        return (
          self.recurring_order_type ===
          ProductRecurringOrderType.RECURRING_ORDERS_ONLY
        );
      },
      hasRecurringOrder() {
        return (
          self.recurring_order_type !== ProductRecurringOrderType.NOT_ALLOWED
        );
      },
      isMulti: () => {
        return !!(self.class === ProductClass.MULTI && self.multi);
      },
      isProposalType(activeProductId, enquireForProposal = false) {
        const actualProduct = self.getActualProduct(activeProductId);
        let isProposalPrice = false;
        if (actualProduct) {
          isProposalPrice =
            !actualProduct.price_info ||
            actualProduct.price_info.getNormalPrice(true) <= 0;
        }

        return enquireForProposal && isProposalPrice;
      },
      isTemporarilyUnavailable(activeProductId) {
        const actualProduct = self.getActualProduct(activeProductId);

        return (
          actualProduct.availability_type === ProductAvailabilityType.NORMAL &&
          !actualProduct.hasEnoughStockAvailable(activeProductId) &&
          !actualProduct.can_be_ordered_out_of_stock
        );
      },
      pathWithActiveProductId(activeProductId) {
        return `${this.path}${activeProductId}/`;
      },
      get allCategories() {
        if (self.all_category_ids) {
          return self.all_category_ids
            .map((categoryId) =>
              resolveIdentifier(Category, getRoot(self), categoryId)
            )
            .filter((category) => !!category);
        }
      },
      get alternativeIds() {
        return (
          self.relatedProducts &&
          self.relatedProducts.alternative_ids &&
          self.relatedProducts.alternative_ids
        );
      },
      get canBeOrderedOutOfStock() {
        return (
          self.availability_type === ProductAvailabilityType.ALLOW_BACKORDER ||
          self.can_be_ordered_out_of_stock
        );
      },
      get canonicalCategoryId() {
        if (self.main_category_id) {
          return self.main_category_id;
        }
        return self.all_category_ids.length > 0
          ? self.all_category_ids[0]
          : null;
      },
      get hasDiscount() {
        if (!self.price_info) {
          return false;
        }

        return (
          self.getQuantityDiscounts().hasDiscounts ||
          self.price_info?.is_discount
        );
      },
      get isIndexableByRobots() {
        // By default we want pages to be indexable.
        // Applicable for ACTIVATE_SECTIONS = false stores.
        if (!self.section) {
          return true;
        }

        if (self.section.status !== SectionStatusType.TEST_MODE) {
          return true;
        }

        return (
          self.section.status === SectionStatusType.TEST_MODE &&
          self.section.visible_to_crawlers
        );
      },
      get listingAvailability() {
        return self.availability_html_for_listing || self.availability_html;
      },
      get manufacturer() {
        return (
          self.manufacturer_id &&
          resolveIdentifier(Manufacturer, getRoot(self), self.manufacturer_id)
        );
      },
      get mainCategory() {
        return self.main_category_id
          ? resolveIdentifier(Category, getRoot(self), self.main_category_id)
          : null;
      },
      get merchantInfo() {
        return self.merchant_info;
      },
      get merchantId() {
        return (
          self.merchant_info && self.merchant_info.id && self.merchant_info.id
        );
      },
      get merchantSiteUrl() {
        return (
          self.merchant_info &&
          self.merchant_info.site_url &&
          self.merchant_info.site_url
        );
      },
      get path() {
        let id = self.multiproduct_id || self.id;

        // Fix the multi product ID in case of case differences
        if (
          self.multi &&
          self.multiproduct_id &&
          !Object.keys(self.multi.children).includes(id)
        ) {
          const actualMultiItem = Object.values(self.multi.children).find(
            (multiChild) =>
              multiChild.id.toUpperCase() === self.multiproduct_id.toUpperCase()
          );

          id = actualMultiItem ? actualMultiItem.id : self.id;
        }

        const slug = self.slug || id;

        return generatePath(Paths.Product, {
          slug,
          id,
        });
      },
      get productCode() {
        return self.actual_code;
      },
      get sellInPackage() {
        return (
          self.package_size && self.package_size > 0 && self.package_size !== 1
        );
      },
      get seoDescription() {
        return self.seo_description;
      },
      get unitName() {
        return self.sellInPackage ? self.package_unit_name : self.stock_unit;
      },
    };
  });

export default Product;
