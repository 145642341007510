import { getParentOfType, types } from 'mobx-state-tree';

import ProductCollectionProduct from '../ProductCollectionProduct';
import ProductMatrix from './ProductMatrix';

const ProductCollectionItem = types
  .model('ProductCollectionItem', {
    column_id: types.number,
    row_id: types.number,
    product: ProductCollectionProduct,
  })
  .views((self) => {
    return {
      getAvailableAmount() {
        return self.product.free_quantity;
      },
      canBeOrderedOutOfStock() {
        return self.product.can_be_ordered_out_of_stock;
      },
      get collection() {
        return getParentOfType(self, ProductMatrix);
      },
    };
  });

export default ProductCollectionItem;
