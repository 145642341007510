import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button } from 'reactstrap';

import Analytics from '../../../analytics/Analytics';
import globalTranslations from '../../../i18n/globalTranslations';
import { modelOf } from '../../../prop-types';
import AccountStore from '../../../store/AccountStore';
import CartStore from '../../../store/CartStore';
import CurrencyStore from '../../../store/CurrencyStore';
import ProductClass from '../../../types/ProductClass';
import RequestState from '../../../types/RequestState';
import { calculateTotal, roundWithPrecision } from '../../../util/number';
import ScrollableAnchor from '../../anchor/ScrollableAnchor';
import Icon from '../../common/Icon';
import ProductCollectionMatrix from '../ProductCollectionMatrix';
import ProductMultiMatrix from '../ProductMultiMatrix';
import { PRODUCT_INFORMATION_BANNER_ID } from '../ProductPageContent';
import FullProduct from '../../../models/product/FullProduct';

@observer
export class ProductMatrix extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedProducts: [],
      analyticsProducts: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.product.id !== this.props.product.id) {
      this.setState({
        selectedProducts: [],
        analyticsProducts: [],
      });
    }
  }

  handleProductQuantity = (newProduct) => {
    const { accountStore, product, activeProductId } = this.props;
    const { selectedProducts } = this.state;
    const newSelectedProducts = selectedProducts;
    const withTax = accountStore.showPricesWithTax;
    const currentProductIndex = selectedProducts.findIndex(
      (selectedProduct) => selectedProduct.id === newProduct.id
    );

    if (currentProductIndex < 0 && !newProduct.qty) {
      return;
    }

    if (currentProductIndex >= 0 && newProduct.qty > 0) {
      newSelectedProducts[currentProductIndex].qty = newProduct.qty;
    } else if (currentProductIndex >= 0 && !newProduct.qty) {
      newSelectedProducts.splice(currentProductIndex, 1);
    } else {
      newSelectedProducts.push(newProduct);
    }

    const analyticsProducts = newSelectedProducts.map((selectedProduct) => {
      const analyticsProductId = activeProductId || selectedProduct.id;

      return {
        product,
        quantity: selectedProduct.qty,
        activeProductId: analyticsProductId,
        price: product.getPrice(withTax, analyticsProductId),
      };
    });

    this.setState({
      selectedProducts: newSelectedProducts,
      analyticsProducts,
    });
  };

  populateCart = () => {
    const { analytics, cartStore, accountStore } = this.props;
    const { selectedProducts, analyticsProducts } = this.state;

    const productsValid = selectedProducts && selectedProducts.length > 0;
    const analyticsValid = analyticsProducts && analyticsProducts.length > 0;

    productsValid &&
      cartStore
        .populate(selectedProducts, accountStore.showMultiProductMatrix)
        .then(() => {
          if (analyticsValid) {
            const totalValue = this.calculateTotalAnalyticsValue();
            analytics.addToCart(analyticsProducts, totalValue);
            analytics.cartStatus({
              cart: cartStore.cart,
            });
          }
        });
  };

  calculateTotalAnalyticsValue = () => {
    const { analyticsProducts } = this.state;
    const precision = 4;
    return roundWithPrecision(
      calculateTotal(
        analyticsProducts.map((product) => product.price * product.quantity),
        precision
      ),
      precision
    );
  };

  renderAddToCartButton = (classname) => {
    const { cartStore } = this.props;
    const loading = cartStore.state === RequestState.LOADING;

    return (
      <Button
        className={classname}
        onClick={this.populateCart}
        color="primary"
        disabled={this.state.formError || loading}
        block
      >
        {loading ? (
          <Icon name="circle-o-notch" spin={loading} />
        ) : (
          <FormattedMessage {...globalTranslations.addToCartButton} />
        )}
      </Button>
    );
  };

  renderImageCarouselHelpText = () => {
    const { product } = this.props;
    const count = product.multi.getMultiElementProperties()?.length ?? 0;
    if (count === 1) {
      return (
        <div className="ProductMatrix__info-help-image">
          <FormattedMessage
            id="matrix.imageHelp"
            defaultMessage="Images can be inspected on fullscreen by clicking on them."
          />
        </div>
      );
    }
  };

  renderNoProductHelpText = () => {
    const { product } = this.props;

    const count = product.multi.getMultiElementProperties()?.length ?? 0;
    if (count >= 2) {
      return (
        <div className="ProductMatrix__info-help-no">
          <Icon name="ban" />
          {' = '}
          <FormattedMessage
            id="matrix.noProductHelp"
            defaultMessage="Variation does not exist."
          />
        </div>
      );
    }

    return null;
  };

  renderMultiMatrixInfo() {
    return (
      <div className="ProductMatrix__info">
        <FormattedMessage {...globalTranslations.productMatrixInfo} />
        {this.renderNoProductHelpText()}
        {this.renderImageCarouselHelpText()}
      </div>
    );
  }

  renderAnchor = () => (
    <ScrollableAnchor
      id={PRODUCT_INFORMATION_BANNER_ID}
      scrollIfInHash={false}
    />
  );

  renderCollectionMatrix = () => {
    const { product, activeProductId } = this.props;
    const collection = product.collection;

    if (
      !collection ||
      (!collection.column && !collection.row) ||
      (!collection.column.elements && !collection.row.elements)
    ) {
      return null;
    }

    return (
      <>
        {this.renderAnchor()}
        <ProductCollectionMatrix
          product={product}
          productMatrix={product.collection}
          activeProductId={activeProductId}
        />
      </>
    );
  };

  renderMultiMatrix = () => {
    const { product } = this.props;

    return (
      <>
        {this.renderAnchor()}
        {this.renderMultiMatrixInfo()}
        {this.renderAddToCartButton('ProductMatrix__add-to-cart-top')}
        <ProductMultiMatrix
          onHandleProductQuantity={this.handleProductQuantity}
          multi={product.multi}
        />
        {this.renderAddToCartButton('ProductMatrix__add-to-cart-bottom')}
      </>
    );
  };

  render() {
    const { product } = this.props;

    if (product.class === ProductClass.COLLECTION) {
      return this.renderCollectionMatrix();
    } else if (product.class === ProductClass.MULTI) {
      return this.renderMultiMatrix();
    }
    return null;
  }
}

ProductMatrix.propTypes = {
  analytics: PropTypes.instanceOf(Analytics).isRequired,
  accountStore: modelOf(AccountStore).isRequired,
  cartStore: modelOf(CartStore).isRequired,
  currencyStore: modelOf(CurrencyStore).isRequired,
  product: modelOf(FullProduct).isRequired,
  activeProductId: PropTypes.string,
};

export default injectIntl(
  inject(
    'analytics',
    'accountStore',
    'cartStore',
    'currencyStore'
  )(ProductMatrix)
);
