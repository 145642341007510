import { every, some, sortBy } from 'lodash';
import { applyPatch, flow, getEnv, getRoot, types } from 'mobx-state-tree';

import Product from '../Product';
import ProductAvailabilityType from '../../types/ProductAvailabilityType';
import ProductClass from '../../types/ProductClass';
import { ProductLicenceTypeType } from '../../types/ProductLicenceType';
import ProductTypeClass from '../../types/ProductTypeClass';
import RequestState, { RequestStateType } from '../../types/RequestState';
import createMSTPatch from '../../util/createMSTPatch';
import { stringify } from '../../util/queryString';
import BundleProductInfo from '../BundleProductInfo';
import DeliveryTime from '../DeliveryTime';
import Price from '../Price';
import ProductAdditionalTab from '../ProductAdditionalTab';
import ProductFile from '../ProductFile';
import RecurringOrders from '../RecurringOrders';
import RelatedProduct from '../RelatedProduct';
import ShippingDetails from '../ShippingDetails';
import SizeGuide from '../SizeGuide';
import StartaxInfo from '../StartaxInfo';
import Stock from '../Stock';
import AdditionalService from './AdditionalService';
import SelectedService from './SelectedService';
import ProductFeature from '../ProductFeature';
import ProductFreeTextField from '../ProductFreeTextField';
import ProductExtraProperty from '../ProductExtraProperty';
import FullProductMulti from './FullProductMulti';
import FullProductMatrix from './FullProductMatrix';
import { isFloat } from '../../util/number';

const FullProduct = types.compose(
  Product,
  types
    .model('FullProduct', {
      additionalServices: types.optional(types.array(AdditionalService), []),
      additionalServicesState: types.optional(
        RequestStateType,
        RequestState.NONE
      ),
      additionalTabs: types.optional(types.array(ProductAdditionalTab), []),
      additionalTabsState: types.optional(RequestStateType, RequestState.NONE),
      allow_file_upload: types.maybeNull(types.boolean),
      available_in_store: types.boolean,
      available_somewhere: types.boolean,
      bundleProductInfos: types.optional(types.array(BundleProductInfo), []),
      bundleProductInfosStateInternal: types.optional(
        RequestStateType,
        RequestState.NONE
      ),
      collection: types.maybeNull(FullProductMatrix),
      date_available: types.string,
      description_long: types.maybeNull(types.string),
      display_weight: types.maybeNull(types.string),
      extra_properties: types.optional(types.array(ProductExtraProperty), []),
      features: types.optional(types.array(ProductFeature), []),
      files: types.optional(types.array(ProductFile), []),
      free_text_fields: types.array(ProductFreeTextField),
      manufacturer_id: types.maybeNull(types.string),
      multi: types.maybeNull(FullProductMulti),
      processing_time: types.maybeNull(DeliveryTime),
      recommended_with_ids: types.optional(types.array(types.string), []),
      recurringOrders: types.optional(RecurringOrders, {}),
      recurringOrdersState: types.optional(RequestStateType, RequestState.NONE),
      recurring_order_price: types.maybeNull(Price),
      required_license: ProductLicenceTypeType,
      relatedProducts: types.optional(RelatedProduct, {}),
      relatedProductsState: types.optional(RequestStateType, RequestState.NONE),
      rotating_images: types.maybeNull(types.array(types.string)),
      rotatingImagesList: types.optional(
        types.map(types.array(types.string)),
        {}
      ),
      rotatingImagesListStates: types.optional(
        types.map(types.optional(RequestStateType, RequestState.NONE)),
        {}
      ),
      selectedServices: types.optional(types.array(SelectedService), []),
      shippingDetails: types.optional(types.map(ShippingDetails), {}),
      shippingStates: types.optional(types.map(RequestStateType), {}),
      sizeGuides: types.optional(types.array(SizeGuide), []),
      sizeGuidesState: types.optional(RequestStateType, RequestState.NONE),
      sold_out: types.maybeNull(types.boolean),
      startaxInfo: types.maybeNull(StartaxInfo),
      stocks: types.optional(types.map(types.array(Stock)), {}),
      stockStates: types.optional(types.map(RequestStateType), {}),
      validatedServices: types.optional(
        types.map(types.array(AdditionalService)),
        {}
      ),
      validatedServicesState: types.optional(
        types.map(types.optional(RequestStateType, RequestState.NONE)),
        {}
      ),
    })
    .actions((self) => {
      const fetchAdditionalServices = function* getAdditionalServices(params) {
        const additionalServicesApi = self.getProductsEndpoint(
          'additional-services'
        );

        return yield getEnv(self).apiWrapper.request(
          `${additionalServicesApi}`,
          {
            params,
          },
          { active_section: null }
        );
      };

      const getLang = () => getRoot(self).languageStore.activeLanguage.code;

      return {
        loadStocks: flow(function* loadStocks(extendedId) {
          self.stockStates.set(extendedId, RequestState.LOADING);
          try {
            const storageStocks = yield getEnv(self).apiWrapper.request(
              `${self.getBaseApi('stocks')}/${extendedId}`,
              {},
              { active_section: null }
            );
            self.setStocks(extendedId, storageStocks);
            self.stockStates.set(extendedId, RequestState.LOADED);
          } catch (e) {
            self.stockStates.set(extendedId, RequestState.ERROR);
            throw e;
          }
        }),
        setStocks: function setStocks(extendedId, stocks) {
          self.stocks.set(extendedId, stocks);
        },
        loadShippingDetails: flow(function* loadShippingDetails({
          activeId,
          state = '',
        }) {
          if (
            self.getActualProductLoadingState({ id: activeId, state }) ===
            RequestState.LOADED
          ) {
            return self.shippingDetails.get(activeId);
          }

          if (!self.isNotAvailable()) {
            self.shippingStates.set(activeId, RequestState.LOADING);

            const shippingApi = self.getProductsEndpoint('shipping', activeId);
            try {
              const shippingDetails = yield getEnv(self).apiWrapper.request(
                `${shippingApi}`,
                {},
                { active_section: null }
              );
              self.setShippingDetails(activeId, shippingDetails);
              self.shippingStates.set(activeId, RequestState.LOADED);
            } catch (e) {
              self.shippingStates.set(activeId, RequestState.ERROR);
              throw e;
            }
          }
        }),
        setShippingDetails: function setShippingDetails(
          activeId,
          shippingDetails
        ) {
          self.shippingDetails.set(activeId, shippingDetails);
        },
        loadPropertyImages: flow(function* loadPropertyImages() {
          if (!self.isNotAvailable()) {
            self.propertyImagesState = RequestState.LOADING;
            const propertyImagesApi =
              self.getProductsEndpoint('property-images');
            try {
              const propertyImages = yield getEnv(self).apiWrapper.request(
                `${propertyImagesApi}`
              );
              self.setPropertyImages(propertyImages);
              self.propertyImagesState = RequestState.LOADED;
            } catch (e) {
              self.propertyImagesState = RequestState.ERROR;
              throw e;
            }
          }
        }),
        setPropertyImages: function setPropertyImages(propertyImages) {
          self.propertyImages = propertyImages;
        },
        loadBundleProductInfos: flow(function* loadBundleProductInfos() {
          self.bundleProductInfosStateInternal = RequestState.LOADING;
          const bundleProductInfosApi =
            self.getProductsEndpoint('bundle-products');
          try {
            const bundleProductInfos = yield getEnv(self).apiWrapper.request(
              `${bundleProductInfosApi}`,
              {},
              { active_section: null }
            );
            self.setBundleProductInfos(bundleProductInfos);
            self.bundleProductInfosStateInternal = RequestState.LOADED;
          } catch (e) {
            self.bundleProductInfosStateInternal = RequestState.ERROR;
            throw e;
          }
        }),
        setBundleProductInfos: function setBundleProductInfos(
          bundleProductInfos
        ) {
          self.bundleProductInfos = bundleProductInfos;
        },
        loadAdditionalTabs: flow(function* loadAdditionalTabs() {
          self.additionalTabsState = RequestState.LOADING;
          const additionalTabsApi = self.getProductsEndpoint('additional-tabs');
          try {
            const additionalTabs = yield getEnv(self).apiWrapper.request(
              `${additionalTabsApi}`,
              {},
              { active_section: null }
            );
            self.setAdditionalTabs(additionalTabs);
            self.additionalTabsState = RequestState.LOADED;
          } catch (e) {
            self.additionalTabsState = RequestState.ERROR;
            throw e;
          }
        }),
        setAdditionalTabs: function setAdditionalTabs(additionalTabs) {
          self.additionalTabs = additionalTabs;
        },
        loadRotatingImagesList: flow(function* loadRotatingImagesList(
          filePath
        ) {
          self.rotatingImagesListStates.set(filePath, RequestState.LOADING);
          const rotatingImagesListApi = self.getProductsEndpoint(
            `rotating-images?${stringify({
              filePath: filePath,
            })}`
          );
          try {
            const rotatingImagesList = yield getEnv(self).apiWrapper.request(
              `${rotatingImagesListApi}`,
              {},
              { active_section: null }
            );

            self.setRotatingImagesList(filePath, rotatingImagesList);
            self.rotatingImagesListStates.set(filePath, RequestState.LOADED);
          } catch (e) {
            self.rotatingImagesListStates.set(filePath, RequestState.ERROR);
            throw e;
          }
        }),
        countQuantityWithPackageSizeDecimals: (quantity) => {
          if (self.sellInPackage && isFloat(self.package_size)) {
            return Math.floor(quantity / self.package_size);
          }

          return quantity;
        },
        setRotatingImagesList: function setRotatingImagesList(
          filePath,
          rotatingImagesList
        ) {
          self.rotatingImagesList.set(filePath, rotatingImagesList);
        },
        loadAdditionalServices: flow(function* loadAdditionalServices(
          category
        ) {
          if (
            category &&
            !self.isNotAvailable() &&
            self.product_type !== ProductTypeClass.GIFT_VOUCHER
          ) {
            self.additionalServicesState = RequestState.LOADING;

            try {
              const additionalServices = yield* fetchAdditionalServices({
                category,
              });

              self.setAdditionalServices(additionalServices);
            } catch (e) {
              self.additionalServicesState = RequestState.ERROR;
              throw e;
            }
          }
        }),
        setAdditionalServices: function setAdditionalServices(
          additionalServices
        ) {
          self.additionalServices = additionalServices;
          const selectedServices = self.additionalServices.map((service) =>
            service.createSelectedService()
          );
          self.selectedServices = selectedServices;
          self.additionalServicesState = RequestState.LOADED;
        },
        updateSelectedServices: function updateSelectedServices(
          selectedServices
        ) {
          const patch = createMSTPatch(self.selectedServices, selectedServices);
          applyPatch(self.selectedServices, patch);
        },
        validateAdditionalServices: flow(function* loadAdditionalServices({
          category,
          postalCode,
        }) {
          if (category && postalCode && !self.isNotAvailable()) {
            self.validatedServicesState.set(
              `${self.id}--${postalCode}`,
              RequestState.LOADING
            );

            try {
              const additionalServices = yield* fetchAdditionalServices({
                category,
                postalCode,
              });

              self.setValidatedServices(additionalServices, postalCode);
            } catch (e) {
              self.validatedServicesState.set(
                `${self.id}--${postalCode}`,
                RequestState.ERROR
              );
              throw e;
            }
          }
        }),
        setValidatedServices: function setValidatedServices(
          additionalServices,
          postalCode
        ) {
          self.validatedServices.set(
            `${self.id}--${postalCode}`,
            additionalServices
          );
          self.validatedServicesState.set(
            `${self.id}--${postalCode}`,
            RequestState.LOADED
          );
        },
        submitReview: flow(function* submitReview(review) {
          const reviewsApi = self.getProductsEndpoint('reviews');
          return yield getEnv(self)
            .apiWrapper.apiAxios()
            .post(`${reviewsApi}`, review);
        }),
        uploadFile: flow(function* uploadFile(file) {
          const formData = new FormData();
          formData.append('file', file);
          yield getEnv(self)
            .apiWrapper.apiAxios()
            .post(
              `${self.getBaseApi('product-file-uploads')}/${self.id}`,
              formData
            );
        }),
        deleteFile: flow(function* deleteFile(fileName) {
          yield getEnv(self)
            .apiWrapper.apiAxios()
            .delete(`${self.getBaseApi('product-file-uploads')}/${self.id}`, {
              data: {
                fileName,
              },
            });
        }),
        loadSizeGuides: flow(function* loadSizeGuides(params) {
          if (!self.isNotAvailable()) {
            self.sizeGuidesState = RequestState.LOADING;
            const sizeGuidesApi = self.getProductsEndpoint('size-guides');
            try {
              const sizeGuides = yield getEnv(self).apiWrapper.request(
                `${sizeGuidesApi}`,
                {
                  params,
                },
                { active_section: null }
              );
              self.setSizeGuides(sizeGuides);
              self.sizeGuidesState = RequestState.LOADED;
            } catch (e) {
              self.sizeGuidesState = RequestState.ERROR;
              throw e;
            }
          }
        }),
        setSizeGuides: function setSizeGuides(guides) {
          self.sizeGuides = guides;
        },
        loadRelatedProducts: flow(function* loadRelatedProducts() {
          self.relatedProductsState = RequestState.LOADING;
          try {
            const relatedProducts = yield getEnv(self).apiWrapper.request(
              self.getProductsEndpoint('related-products', self.id) +
                `?${getLang()}`
            );
            self.setRelatedProducts(relatedProducts);
            self.relatedProductsState = RequestState.LOADED;
          } catch (e) {
            self.relatedProductsState = RequestState.ERROR;
            throw e;
          }
        }),
        setRelatedProducts: (relatedProducts) => {
          self.relatedProducts = relatedProducts;
        },
        loadRecurringOrders: flow(function* loadRecurringOrders() {
          self.recurringOrdersState = RequestState.LOADING;
          try {
            const recurringOrders = yield getEnv(self).apiWrapper.request(
              self.getProductsEndpoint('recurring-orders', self.id)
            );
            self.setRecurringOrders(recurringOrders);
            self.recurringOrdersState = RequestState.LOADED;
          } catch (e) {
            self.recurringOrdersState = RequestState.ERROR;
            throw e;
          }
        }),
        setRecurringOrders: (recurringOrders) => {
          self.recurringOrders = recurringOrders;
        },
        sortProductImages: (key) => {
          self.images = self.images && sortBy(self.images, [key]);
        },
      };
    })
    .views((self) => {
      return {
        findStorageStock: (extendedId, storageId) => {
          return self.stocks.get(extendedId).find((stock) => {
            return stock.storage_id === storageId;
          });
        },
        getValidatedServices: ({ postalCode }) => {
          return self.validatedServices.get(`${self.id}--${postalCode}`);
        },
        getValidatedService: ({ postalCode, serviceId }) => {
          const validatedServices = self.getValidatedServices({
            postalCode,
          });

          return (
            self.ifValidatedServicesExists({ postalCode }) &&
            validatedServices.find((service) => service.id === serviceId)
          );
        },
        hasProperties(propertyElementMap) {
          return every(Object.keys(propertyElementMap), (propertyId) => {
            const valueId = propertyElementMap[propertyId];
            return some(
              self.extra_properties,
              (extraProperty) =>
                extraProperty.id === Number(propertyId) &&
                extraProperty.value_id === valueId
            );
          });
        },
        ifServiceIsRequiredAndRequiresPostalCodeValidation: () => {
          return self.additionalServices.some(
            (service) => service.is_required && service.requires_area_validation
          );
        },
        ifServiceRequiresPostalCodeValidation: () => {
          return self.additionalServices.some(
            (service) => service.requires_area_validation
          );
        },
        ifValidServices: ({ productId, postalCode }) => {
          const validatedServices = self.ifValidatedServicesExists({
            postalCode,
          });
          if (!validatedServices) {
            return;
          }

          const services = self.validatedServices.get(
            `${productId}--${postalCode}`
          );
          return services.some((service) => service.valid_postal_code);
        },
        ifValidatedServicesExists: ({ postalCode }) => {
          const services = self.validatedServices.get(
            `${self.id}--${postalCode}`
          );

          return !!services && services.length > 0;
        },
        isBlockedFromStoreReservation(excludePropertyId) {
          return (
            excludePropertyId &&
            (!!self.extra_properties.some(
              (property) => property.id === excludePropertyId
            ) ||
              !!self.features.some(
                (property) => property.id === excludePropertyId
              ))
          );
        },
        isNotAvailable() {
          return (
            self.availability_type === ProductAvailabilityType.CLOSEOUT &&
            self.sold_out
          );
        },
        get alternativeIds() {
          return (
            self.relatedProducts &&
            self.relatedProducts.alternative_ids &&
            self.relatedProducts.alternative_ids
          );
        },
        get breadcrumbs() {
          return self.breadcrumbsForCategory(self.mainCategory);
        },
        get bundleProductInfosState() {
          const cannotHaveAnyBundleProducts =
            self.recommended_with_ids.length === 0;
          if (cannotHaveAnyBundleProducts) {
            return RequestState.LOADED;
          }
          return self.bundleProductInfosStateInternal;
        },
        get extraPropertiesMap() {
          let extraPropertiesMap = {};
          self.extra_properties.forEach((property) => {
            extraPropertiesMap = {
              ...extraPropertiesMap,
              [property.id]: property.value_id,
            };
          });

          return extraPropertiesMap;
        },
        get productProperties() {
          const hiddenProperties = new Set(
            getRoot(self).configStore.productPage.hiddenProperties
          );
          let extraProperties = [];
          let features = [];

          if (self.extra_properties.length) {
            extraProperties = self.extra_properties.filter(
              (property) => !hiddenProperties.has(property.id)
            );
          }

          if (self.features.length) {
            features = self.features.filter(
              (property) => !hiddenProperties.has(property.id)
            );
          }

          return {
            extraProperties,
            features,
          };
        },
        get recommendedIds() {
          return (
            self.relatedProducts &&
            self.relatedProducts.recommended_ids &&
            self.relatedProducts.recommended_ids
          );
        },
        get seoTitle() {
          const modelInSeoTitle =
            self.class !== ProductClass.MULTI && self.model;
          const nameTitle =
            self.multiproduct_title !== null
              ? self.multiproduct_title
              : self.name;
          return modelInSeoTitle ? `${nameTitle} ${self.model}` : nameTitle;
        },
        get uploadedFiles() {
          const productFiles = getRoot(self).productStore.uploadedFiles.find(
            (entry) => entry.id === self.id
          );
          return productFiles ? productFiles.files : [];
        },
      };
    })
);

export default FullProduct;
