import loadable from '@loadable/component';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { PopoverBody, Row, Table } from 'reactstrap';

import classNames from 'classnames';
import globalTranslations from '../../../i18n/globalTranslations';
import ProductProperty from '../../../models/product/ProductProperty';
import ProductMulti from '../../../models/product/ProductMulti';
import { modelOf } from '../../../prop-types';
import AccountStore from '../../../store/AccountStore';
import ConfigStore from '../../../store/ConfigStore';
import { initialImageOrigin } from '../../common/ImageLightbox/ImageLightboxProductImage';
import ProductAvailabilityText from '../ProductAvailabilityText';
import ProductImage from '../ProductImage';
import ProductMatrixCell from '../ProductMatrixCell';
import ProductMatrixTooltip from '../ProductMatrixTooltip';
import ProductPackageSize from '../ProductPackageSize';
import ProductPagePrice from '../ProductPagePrice';
import ProductPrice from '../ProductPrice';
import ProductQuantityDiscounts from '../ProductQuantityDiscounts';
import FullProductMulti from '../../../models/product/FullProductMulti';

const ImageLightbox = loadable(() =>
  import(
    /* webpackChunkName: "common" */ '../../common/ImageLightbox/ImageLightboxModal'
  )
);

const createPropertyElement = (propertyID, elementID) => {
  return { [propertyID]: elementID };
};

const ProductMultiPropertyList = ({
  accountStore,
  configStore,
  multi,
  property,
  onHandleProductQuantity,
}) => {
  if (!property) {
    return null;
  }

  const multiPropertyListElement = useRef(null);

  const [mainImageIndex, setMainImageIndex] = useState(0);
  const [lightboxIsOpen, toggleLightbox] = useState(false);
  const [activeProductId, setActiveProduct] = useState(null);

  const withTax = accountStore.showPricesWithTax;
  const quantityDiscountDisplayStyle =
    configStore.product.productQuantityDiscountDisplayStyle;

  useEffect(() => {
    if (activeProductId) openLightbox();
  }, [activeProductId]);

  let lightboxInitialState = {};

  const imageLightboxOnClickHandler = useCallback(() => {
    toggleLightbox(false);
    setActiveProduct(null);
  }, []);

  const getImageLightbox = () => {
    const childProduct = multi.findChild(activeProductId);

    if (childProduct?.images.length) {
      childProduct.images.forEach((_, index) => {
        lightboxInitialState = {
          ...lightboxInitialState,
          [index]: {
            focus: false,
            scale: 1,
            origin: initialImageOrigin,
          },
        };
      });

      lightboxInitialState.selectedImage = mainImageIndex;

      return (
        <ImageLightbox
          images={childProduct.images}
          mainImageIndex={mainImageIndex}
          onClick={imageLightboxOnClickHandler}
          lightboxIsOpen={lightboxIsOpen}
          initialState={lightboxInitialState}
          product={childProduct}
        />
      );
    }

    return null;
  };

  const openLightbox = () => {
    const childProduct = multi.findChild(activeProductId);
    if (childProduct?.images.length) {
      const childProductImageIndex = childProduct.images.indexOf(
        childProduct.images[0]
      );

      setMainImageIndex(childProductImageIndex);
      toggleLightbox(true);
    }
  };

  const setActiveChildProduct = useCallback(
    (childProductId) => setActiveProduct(childProductId),
    []
  );

  const shouldRenderProductCode = () => {
    const showProductCode = configStore.productPage.showProductCode;
    const showAdditionalProductCode =
      configStore.productPage.showAdditionalProductCode;

    if (!showProductCode || !showAdditionalProductCode) {
      return false;
    }

    return true;
  };

  const renderPropertyRows = () => {
    const renderPrice = (childProduct) => {
      const { hasPriceRange } = childProduct.getDiscountInfo(
        withTax,
        quantityDiscountDisplayStyle
      );

      const price = (
        <ProductPrice
          product={childProduct}
          withTax={withTax}
          priceInfo={childProduct.price_info}
        />
      );

      return (
        <ProductPagePrice
          price={price}
          product={childProduct}
          quantityDiscounts={childProduct.getQuantityDiscounts().discounts}
          discountInverted={hasPriceRange}
          onOverridePrice={({
            ifRenderPrice,
            quantityBasedPrice,
            discountSign,
          }) => {
            return (
              <div className="ProductPagePrice__price-container">
                <ProductPrice
                  product={childProduct}
                  withTax={withTax}
                  priceInfo={childProduct.price_info}
                  onOverrideContent={({
                    className,
                    isMultiProductPicker,
                    isSecondaryPrice,
                    priceInfo,
                    product,
                    isDiscount,
                    showTaxExcludedInfo,
                    showTaxIncludedInfo,
                    showUnit,
                    withTax,
                    productBundle,
                    activeProductId,
                    hidePrice,
                    hideDiscount,
                  }) => {
                    if (!ifRenderPrice) {
                      return null;
                    }

                    return (
                      <div className="ProductPrice">
                        <div className="ProductPrice__with-discount">
                          <ProductPrice.WithDiscount
                            className={className}
                            hidePrice={hidePrice}
                            isDiscount={isDiscount}
                            isMultiProductPicker={isMultiProductPicker}
                            isSecondaryPrice={isSecondaryPrice}
                            priceInfo={priceInfo}
                            product={product}
                            showTaxExcludedInfo={showTaxExcludedInfo}
                            showTaxIncludedInfo={showTaxIncludedInfo}
                            showUnit={showUnit}
                            withTax={withTax}
                          />
                          {discountSign}
                        </div>
                        <div className="ProductPrice__rrp-price">
                          <ProductPrice.PriceRRP
                            activeProductId={activeProductId}
                            className={className}
                            hideDiscount={hideDiscount}
                            isDiscount={isDiscount}
                            isMultiProductPicker={isMultiProductPicker}
                            isSecondaryPrice={isSecondaryPrice}
                            priceInfo={priceInfo}
                            product={product}
                            productBundle={productBundle}
                            showTaxExcludedInfo={showTaxExcludedInfo}
                            showTaxIncludedInfo={showTaxIncludedInfo}
                            withTax={withTax}
                          />
                        </div>
                      </div>
                    );
                  }}
                />
                {quantityBasedPrice && (
                  <div className="ProductPagePrice__quantity-based-price">
                    {quantityBasedPrice}
                    {!ifRenderPrice && discountSign}
                  </div>
                )}
              </div>
            );
          }}
        />
      );
    };

    const renderTooltip = (tooltipIsOpen, childProduct) => {
      if (childProduct.getQuantityDiscounts().hasDiscounts) {
        return (
          <ProductMatrixTooltip
            product={childProduct}
            isOpen={tooltipIsOpen}
            placement="left"
          >
            <PopoverBody>
              <ProductQuantityDiscounts
                className="ProductQuantityDiscounts__tooltip"
                quantityDiscounts={
                  childProduct.getQuantityDiscounts().discounts
                }
                withTax={withTax}
                stockUnit={childProduct.stock_unit}
              />
            </PopoverBody>
          </ProductMatrixTooltip>
        );
      }

      return null;
    };

    return property.elements.map((element) => {
      const propertyElement = createPropertyElement(property.id, element.id);
      const childProduct = multi.findChildWithProperties(propertyElement);
      // childProduct.extra_properties can be an empty array
      // in cases where site init has been on product page
      // then navigated to multiproduct
      if (!childProduct) {
        return null;
      }

      return (
        <tr
          key={element.id}
          className="ProductMultiPropertyList__product-cell-row"
        >
          {shouldRenderProductCode() && (
            <td className="ProductMultiPropertyList__cell">
              {childProduct.productCode}
            </td>
          )}
          <td
            className="ProductMultiPropertyList__cell"
            onClick={() => setActiveChildProduct(childProduct.id)}
          >
            <ProductImage
              product={childProduct}
              productImage={childProduct.images[0]}
              size="small"
              forceAspectRatio={false}
              lazyLoading={false}
            />
          </td>
          <td className="ProductMultiPropertyList__cell">
            {childProduct.name}
          </td>
          <td className="ProductMultiPropertyList__cell">
            <ProductAvailabilityText
              availabilityHtml={childProduct.availability_html}
            />
          </td>
          <td className="ProductMultiPropertyList__cell">{element.name}</td>
          <td className="ProductMultiPropertyList__cell">
            <div className="ProductMultiPropertyList__price">
              {renderPrice(childProduct)}
              {childProduct.sellInPackage && (
                <ProductPackageSize
                  product={childProduct}
                  withTax={withTax}
                  withSimpleText={true}
                />
              )}
            </div>
          </td>
          <td className="ProductMultiPropertyList__cell">
            <div
              className={classNames('ProductMultiPropertyList__quantity', {
                'ProductMultiPropertyList__quantity--with-package-size':
                  childProduct.sellInPackage,
              })}
            >
              <ProductMatrixCell
                key={childProduct?.productCode ?? 0}
                className="ProductMultiPropertyList__quantity-input"
                product={childProduct}
                onHandleProductQuantity={onHandleProductQuantity}
                cellCount={1}
                propertyListRef={multiPropertyListElement}
                onRenderTooltip={(tooltipIsOpen) =>
                  renderTooltip(tooltipIsOpen, childProduct)
                }
              />
            </div>
          </td>
        </tr>
      );
    });
  };

  const renderHeaderRow = () => {
    return (
      <tr className="ProductMultiPropertyList__product-header-row">
        {shouldRenderProductCode() && (
          <th className="ProductMultiPropertyList__header">
            <FormattedMessage {...globalTranslations.productCodeTitle} />
          </th>
        )}
        <th className="ProductMultiPropertyList__header">
          <FormattedMessage {...globalTranslations.imageTitle} />
        </th>
        <th className="ProductMultiPropertyList__header">
          <FormattedMessage {...globalTranslations.productNameTitle} />
        </th>
        <th className="ProductMultiPropertyList__header">
          <FormattedMessage {...globalTranslations.availabilityTitle} />
        </th>
        <th className="ProductMultiPropertyList__header">{property.name}</th>
        <th className="ProductMultiPropertyList__header">
          <FormattedMessage {...globalTranslations.priceTitle} />
        </th>
        <th className="ProductMultiPropertyList__header">
          <FormattedMessage {...globalTranslations.quantityTitle} />
        </th>
      </tr>
    );
  };

  const shouldRenderLightBox =
    lightboxIsOpen && multi.findChild(activeProductId)?.images.length > 0;

  return (
    <div className="ProductMultiPropertyList" ref={multiPropertyListElement}>
      <Row noGutters>
        <Table>
          <thead>{renderHeaderRow()}</thead>
          <tbody>{renderPropertyRows()}</tbody>
        </Table>
        {shouldRenderLightBox && getImageLightbox()}
      </Row>
    </div>
  );
};

ProductMultiPropertyList.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  multi: modelOf(FullProductMulti).isRequired,
  property: modelOf(ProductProperty).isRequired,
  onHandleProductQuantity: PropTypes.func.isRequired,
};

export default injectIntl(
  inject('accountStore', 'configStore')(observer(ProductMultiPropertyList))
);
