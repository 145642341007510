import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Container } from 'reactstrap';

import ContentPopups from '../../../components/ad/ContentPopups';
import HelperNavigation from '../../../components/common/HelperNavigation';
import SharePopover from '../../../components/common/SharePopover';
import GenericMeta from '../../../components/head/GenericMeta';
import SidebarLayout from '../../../components/layout/SidebarLayout';
import ProductPageContent from '../../../components/product/ProductPageContent';
import ProductBaseSchema from '../../../components/schema/product/ProductBaseSchema';
import DefaultSidebar from '../../../components/sidebar/DefaultSidebar';
import SidebarSkeleton from '../../../components/skeleton/SidebarSkeleton';
import { modelOf } from '../../../prop-types';
import { LocationContextPropType } from '../../../services/LocationContext';
import RouteService from '../../../services/RouteService';
import AdStore from '../../../store/AdStore';
import CategoryStore from '../../../store/CategoryStore';
import ConfigStore from '../../../store/ConfigStore';
import SectionStore from '../../../store/SectionStore';
import TrackingStore from '../../../store/TrackingStore';
import UIStore from '../../../store/UIStore';
import ProductTypeClass from '../../../types/ProductTypeClass';
import RequestState from '../../../types/RequestState';
import TrackingEventType from '../../../types/TrackingPageType';
import FullProduct from '../../../models/product/FullProduct';

const ProductPageContainer = ({
  adStore,
  categoryStore,
  configStore,
  sectionStore,
  trackingStore,
  uiStore,
  routeService,
  locationContext,
  setActiveProductId,
  product,
  activeProductId,
}) => {
  useEffect(() => {
    maybeUpdateActiveCategory();
    loadBanners();
  }, []);

  useEffect(() => {
    loadSizeGuides();
    sendTrackingData();
  }, [activeProductId]);

  const loadSizeGuides = () => {
    const activeProduct = product.getActualProduct(
      activeProductId || product.id
    );

    if (
      activeProduct.product_type === ProductTypeClass.GIFT_VOUCHER ||
      activeProduct.sizeGuidesState !== RequestState.NONE
    ) {
      return;
    }

    const sizeGuidesParams = getCommonParams();

    activeProduct.loadSizeGuides(sizeGuidesParams).catch((e) => {
      if (e.response && e.response.status !== 404) {
        console.error(e);
      }
    });
  };

  const loadBanners = () => {
    const params = getAdSearchParams();

    if (shouldHaveAds()) {
      adStore.loadAds(params, '/product').catch((e) => {
        console.error(e);
      });
    }
  };

  const getAdSearchParams = () => {
    const commonAdParams = getCommonParams();

    commonAdParams.product = product.id;

    if (product.all_category_ids.length > 0) {
      commonAdParams.allCategories = product.all_category_ids;
    }

    if (product.manufacturer_id) {
      commonAdParams.manufacturer = product.manufacturer_id;
    }

    return commonAdParams;
  };

  const getCommonParams = () => {
    const commonParams = {};

    if (sectionStore.activeSection) {
      commonParams.section = sectionStore.activeSection.id;
    }

    if (categoryStore.activeCategory) {
      commonParams.category = categoryStore.activeCategory.id;
    }

    return commonParams;
  };

  const shouldHaveAds = () => {
    return adStore.productPageAds.length > 0;
  };

  const shouldHaveSidebarAds = () => {
    return adStore.productPageAds.indexOf('SIDEBAR_BANNER') !== -1;
  };

  const shouldHavePopup = () => {
    return adStore.productPageAds.indexOf('POPUP_CONTENT') !== -1;
  };

  const getActiveHierarchyCategory = () => {
    // Return only one result.
    return product.allCategories.filter((category) => {
      if (category.id !== product.main_category_id) {
        if (
          category.hierarchy.findIndex(
            (filteredCategory) =>
              filteredCategory.id === categoryStore.latestActiveCategory.id
          ) !== -1
        ) {
          return true;
        }
      }
      return false;
    })[0];
  };

  const getCategoryBySection = () => {
    // Will return one result if the current hierarchy is not the main one.
    return product.allCategories.filter((category) => {
      if (category.id !== product.main_category_id) {
        if (
          category.hierarchy.findIndex((filteredCategory) =>
            sectionStore.activeSection.includesCategory(filteredCategory)
          ) !== -1
        ) {
          return true;
        }
      }
      return false;
    })[0];
  };

  const maybeUpdateActiveCategory = () => {
    // Default category is products main category.
    let activeCategory = product.main_category_id;

    if (product.allCategories.length === 1) {
      categoryStore.setActiveCategory(activeCategory);
      return;
    }

    const mainCategory = product.main_category_id
      ? categoryStore.findCategoryById(product.main_category_id)
      : null;

    if (categoryStore.latestActiveCategory) {
      const activeIsMainCategory =
        mainCategory &&
        mainCategory.hierarchy.findIndex(
          (category) => category.id === categoryStore.latestActiveCategory.id
        ) !== -1;

      // When currently active category is not its main category
      // the correct one needs to be scanned from product other categories.
      if (
        !activeIsMainCategory &&
        product.belongsToCategory(categoryStore.latestActiveCategory.id)
      ) {
        const targetCategory = getActiveHierarchyCategory();
        if (targetCategory) {
          activeCategory = targetCategory.id;
        }
      }
    } else {
      if (sectionStore.activeSection) {
        const target = getCategoryBySection();
        if (target) {
          activeCategory = target.id;
        }
      }
    }
    categoryStore.setActiveCategory(activeCategory);
  };

  const sendTrackingData = () => {
    const actualProduct = product && product.getActualProduct(activeProductId);
    if (
      !configStore.integrations.spotmore.enabled ||
      typeof document === 'undefined' ||
      !actualProduct ||
      !activeProductId
    ) {
      return null;
    }

    if (configStore.integrations.spotmore.enabled && window.crmtracker) {
      window.crmtracker.sendData();
    }

    trackingStore.setPageView({
      categoryId: categoryStore.activeCategory
        ? categoryStore.activeCategory.id
        : null,
      categoryName: categoryStore.activeCategory
        ? categoryStore.activeCategory.name
        : null,
      page: TrackingEventType.PRODUCT,
      parentCategoryId: categoryStore.activeCategory
        ? categoryStore.activeCategory.parent_id
        : null,
      productActive: typeof actualProduct !== 'undefined',
      productId: activeProductId,
      productName: actualProduct.name,
      refererUrl: document.referrer,
      visitedUrl: window.location.href,
    });
    trackingStore.postPageView();
  };

  const getShareImageUrl = () => {
    if (!product.images || product.images.length === 0) {
      return null;
    }
    const productImage = product.images[0];

    return productImage.sizes['full'].length > 0 &&
      productImage.sizes['full'].startsWith('http')
      ? productImage.sizes['full']
      : `${locationContext.protocol}//${locationContext.host}${productImage.sizes['full']}`;
  };

  const renderShareButtons = (media) => {
    return (
      <SharePopover
        media={media}
        productId={product.id}
        url={locationContext.href}
        title={window.document.title || ''}
      />
    );
  };

  const breadcrumbs = product
    ? routeService.transformBreadCrumbs(
        product.breadcrumbsForCategory(categoryStore.activeCategory)
      )
    : [];

  const adSearchParams = getAdSearchParams();
  const shareImageUrl = getShareImageUrl();
  const shareButtons = product && renderShareButtons(shareImageUrl);

  return (
    <Container className="ProductPage__container">
      <SidebarLayout
        sidebarPlaceHolder={!uiStore.isMobile ? <SidebarSkeleton /> : null}
        sidebar={
          !uiStore.isMobile &&
          product && (
            <DefaultSidebar
              bannerSearchParams={
                shouldHaveSidebarAds() ? adSearchParams : null
              }
            />
          )
        }
        content={
          <>
            {!uiStore.isMobile && (
              <HelperNavigation
                breadcrumbsPath={breadcrumbs}
                withGoBackLink
                rightSide={shareButtons}
              />
            )}
            <GenericMeta name="twitter:card" content="summary" />
            <GenericMeta property="og:image" content={shareImageUrl} />
            <GenericMeta property="og:type" content="product.item" />
            <ProductBaseSchema product={product} />
            <ProductPageContent
              shareButtons={shareButtons}
              product={product}
              activeProductId={activeProductId}
              setActiveProductId={setActiveProductId}
              adSearchParams={adSearchParams}
            />
            {shouldHavePopup() && (
              <ContentPopups searchParams={adSearchParams} />
            )}
          </>
        }
      />
    </Container>
  );
};

ProductPageContainer.propTypes = {
  adStore: modelOf(AdStore).isRequired,
  categoryStore: modelOf(CategoryStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  sectionStore: modelOf(SectionStore).isRequired,
  trackingStore: modelOf(TrackingStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  locationContext: LocationContextPropType.isRequired,
  setActiveProductId: PropTypes.func.isRequired,
  product: modelOf(FullProduct).isRequired,
  activeProductId: PropTypes.string,
};

export default inject(
  'adStore',
  'categoryStore',
  'configStore',
  'sectionStore',
  'trackingStore',
  'uiStore',
  'routeService',
  'locationContext'
)(observer(ProductPageContainer));
