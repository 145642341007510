import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import RouterPropTypes from 'react-router-prop-types';

import NavigationAddToCartRow from '../../components/cart/NavigationAddToCartRow';
import ScrollReset from '../../components/common/ScrollReset';
import Page from '../../components/layout/Page';
import { modelOf } from '../../prop-types';
import RouteService from '../../services/RouteService';
import CategoryStore from '../../store/CategoryStore';
import ProductStore from '../../store/ProductStore';
import SectionStore from '../../store/SectionStore';
import UIStore from '../../store/UIStore';
import CommonPage from '../../types/CommonPage';
import ProductClass from '../../types/ProductClass';
import TrackingEventType from '../../types/TrackingPageType';
import {
  addParametersToPath,
  parse,
  removeQueryParameters,
} from '../../util/queryString';
import {
  fixPercentDecodingForParam,
  pathFragmentMatchesLocation,
} from '../../util/url';
import ProductPageContainer from '../../components/product/ProductPageContainer';
import PageSkeleton from '../../components/skeleton/PageSkeleton';
import MobilePageSkeleton from '../../components/skeleton/MobilePageSkeleton';
import generatePath from '../../util/generatePath';
import useFetchData from './hook';
import { globalUserMessageDataKey } from '../../util/constants';
import globalUserMessages from '../../i18n/globalUserMessages';
import Paths from '../../types/Paths';
import { blockTestRender } from '../../util/process';

const ProductPage = ({
  categoryStore,
  productStore,
  sectionStore,
  uiStore,
  routeService,
  location,
  history,
  match,
}) => {
  useEffect(() => {
    return () => {
      if (blockTestRender()) {
        productStore.deleteFullProducts([getProductId()]);
      }
    };
  }, [match.params.id]);

  // TODO
  // This function is a workaround for an issue regarding percent encoded
  // URLs in React Router and its history library.
  // It should be fixed in the next version of history > 4.9.0, which had
  // not yet been published at the time of this alternate solution;
  // when that version is out, this fix can be removed.
  //
  // See: https://github.com/ReactTraining/history/issues/505
  const getProductId = () => {
    return fixPercentDecodingForParam(match.params.id);
  };

  const getCurrentSearchState = () => {
    return productStore.fullProductStates.get(getProductId());
  };

  const getCurrentProduct = () => {
    return productStore.fullProducts.get(getProductId());
  };

  let urlParams = null;
  if (location.search) {
    urlParams = parse(location.search);
  }
  const id = getProductId();

  const params = {
    id,
    urlParams,
    state: getCurrentSearchState(),
  };

  useFetchData({ productStore, params });

  const product = getCurrentProduct();
  if (productStore?.lastError?.httpStatusCode === 404) {
    if (!productStore?.lastError.fallbackCategoryId) {
      return <Redirect to={routeService.getPath(Paths.NotFoundPage)} />;
    }

    // CustomEvent is not supported by node.
    if (!window.isSSR) {
      const customEvent = new CustomEvent(
        globalUserMessageDataKey.globalUserMessage,
        {
          detail: {
            ...globalUserMessages.productNotExist,
          },
        }
      );
      window.dispatchEvent(customEvent);
    }

    const path =
      categoryStore.findCategoryById(productStore.lastError.fallbackCategoryId)
        ?.path ?? Paths.FrontPage;

    return <Redirect to={routeService.getPath(path)} />;
  }
  /* Product data is required to proceed so meanwhile we render skeleton */
  if (!product || product.id !== match.params.id) {
    return uiStore.isMobile ? (
      <>
        <ScrollReset key={getProductId()} />
        <MobilePageSkeleton />
      </>
    ) : (
      <>
        <ScrollReset key={getProductId()} />
        <PageSkeleton />
      </>
    );
  }

  const redirectToProduct = (product) => {
    const productPath = routeService.getProductPath(product);
    return <Redirect to={productPath} />;
  };

  // The same workaround is also applied here.
  const getActiveProductId = () => {
    let activeProductId;
    if (product.class === ProductClass.COLLECTION) {
      activeProductId = match.params.activeProductId;
    } else if (product.class === ProductClass.MULTI) {
      activeProductId = match.params.activeProductId;
    } else {
      activeProductId = product.id;
    }

    return fixPercentDecodingForParam(activeProductId);
  };

  // If the product does not belong to the active section, redirect.
  if (
    (sectionStore.activeSection &&
      !product.belongsToSection(sectionStore.activeSection.id)) ||
    (!sectionStore.activeSection &&
      product.belongsToSomeSection(sectionStore.sectionIds))
  ) {
    return <Redirect to={routeService.getProductPath(product)} />;
  }

  const activeProductId = getActiveProductId();
  const expectedPath =
    activeProductId && match.params.activeProductId
      ? product.pathWithActiveProductId(activeProductId)
      : product.path;

  if (!pathFragmentMatchesLocation(expectedPath, location.pathname)) {
    const url = `${routeService.getProductPath(product, expectedPath)}${
      location.search
    }${location.hash}`;
    return <Redirect to={url} />;
  }

  // If active collection or multi item is not valid, redirect to parent.
  if (product.class === ProductClass.COLLECTION) {
    if (
      getActiveProductId() &&
      !product.collection.getItemWithProductId(getActiveProductId())
    ) {
      return redirectToProduct(product);
    }
  }

  if (product.class === ProductClass.MULTI) {
    if (
      getActiveProductId() &&
      !product.getActualProduct(getActiveProductId())
    ) {
      return redirectToProduct(product);
    }
  }

  // If the product is MULTI_CHILD, redirect to parent
  if (product.class === ProductClass.MULTI_CHILD) {
    const productPath = routeService.getProductPath(
      product,
      product.pathWithActiveProductId(product.id)
    );
    return <Redirect to={productPath} />;
  }

  const getManufacturerId = () => {
    return product?.manufacturer?.id;
  };

  const setQueryParams = () =>
    removeQueryParameters({
      location: location,
      params: ['columnId', 'rowId'],
    });

  const setActiveProductId = ({ activeProductId, queryParams }) => {
    // Store all query params to reinsert back the remaining params we want to preserve in the url
    // after history.replace
    const allQueryParams = setQueryParams();
    const params = { ...match.params, activeProductId };

    const activeProductPath = addParametersToPath(
      generatePath(match.path, params),
      {
        ...queryParams,
        ...allQueryParams,
      }
    );

    history.replace(activeProductPath);
  };

  return (
    <Page
      name={CommonPage.PRODUCT}
      className={classNames('ProductPage', getManufacturerId(product))}
      onDefinedTrackingPage={TrackingEventType.PRODUCT}
    >
      <ScrollReset key={getProductId()} />
      <NavigationAddToCartRow
        product={product}
        activeProductId={getActiveProductId()}
      />
      <ProductPageContainer
        product={product}
        activeProductId={getActiveProductId()}
        setActiveProductId={setActiveProductId}
      />
    </Page>
  );
};

ProductPage.propTypes = {
  categoryStore: modelOf(CategoryStore).isRequired,
  productStore: modelOf(ProductStore).isRequired,
  sectionStore: modelOf(SectionStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  location: RouterPropTypes.location.isRequired,
  history: RouterPropTypes.history.isRequired,
  match: RouterPropTypes.match.isRequired,
};

export default inject(
  'categoryStore',
  'productStore',
  'sectionStore',
  'uiStore',
  'routeService'
)(withRouter(observer(ProductPage)));
