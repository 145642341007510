import { types } from 'mobx-state-tree';
import { blockScroll, unblockScroll } from '../../util/dom';

const Modal = types
  .model('Modal', {
    isOpen: types.optional(types.boolean, false),
  })
  .actions((self) => {
    return {
      toggle() {
        self.isOpen = !self.isOpen;
        // TODO: Opening several modals and closing one of them unblocks scrolling even if underlying modal is still open.
        self.isOpen ? blockScroll() : unblockScroll();
      },
    };
  });

export default Modal;
